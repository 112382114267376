import api from './index'
import { useQuery, useMutation, queryCache } from 'react-query'

// API Calls
export const fetchTerms = async () => (await api.get('/assessments/terms')).data
const fetchStats = async () => (await api.get('/assessments/stats')).data
const fetchAssessments = async () => (await api.get('/assessments')).data
const fetchAssessment = async (_key, uuid) =>
  (await api.get(`/assessments/${uuid}`)).data
const createAssessment = async ({ name, default_locale }) =>
  (await api.post('/assessments', { name, default_locale })).data
const updateAssessment = async ({
  uuid,
  name,
  default_locale,
  custom_overrides,
  concepts,
  demographics
}) =>
  (
    await api.put(`/assessments/${uuid}`, {
      name,
      default_locale,
      custom_overrides,
      concepts,
      demographics
    })
  ).data
const deleteAssessment = async uuid => {
  await api.delete(`/assessments/${uuid}`)
  return uuid
}

// Hooks
export const useAssessments = () => useQuery('assessments', fetchAssessments)
export const useAssessment = uuid =>
  useQuery(['assessments', uuid], fetchAssessment)
export const useAssessmentStats = () => useQuery('assessment-stats', fetchStats)
export const useCreateAssessment = () =>
  useMutation(createAssessment, {
    onMutate: vals => {
      const previousAssessments = queryCache.getQueryData('assessments')

      queryCache.setQueryData('assessments', old => [
        ...old,
        {
          id: 0,
          uuid: 'new',
          name: vals.name,
          default_locale: vals.locale,
          concepts: [],
          demographics: []
        }
      ])

      // Rollback function
      return () => queryCache.setQueryData('assessments', previousAssessments)
    },
    onError: (_err, _vals, rollback) => rollback(),
    onSuccess: () => queryCache.invalidateQueries('assessments')
  })
export const useUpdateAssessment = () =>
  useMutation(updateAssessment, {
    onMutate: vals => {
      const previousAssessment = queryCache.getQueryData([
        'assessments',
        vals.uuid
      ])

      queryCache.setQueryData(['assessments', vals.uuid], old => ({
        ...old,
        ...vals
      }))

      return () =>
        queryCache.setQueryData(['assessments', vals.uuid], previousAssessment)
    },
    onError: (_err, _vals, rollback) => rollback(),
    onSuccess: data =>
      queryCache.setQueryData(['assessments', data.uuid], () => data)
  })
export const useDeleteAssessment = () =>
  useMutation(deleteAssessment, {
    onMutate: uuid => {
      const { [uuid]: deleting, ...rest } =
        queryCache.getQueryData('assessments')

      queryCache.setQueryData('assessments', rest)

      // Rollback function
      return () => queryCache.setQueryData('assessments', { deleting, ...rest })
    },
    onError: (_err, _vals, rollback) => rollback(),
    onSuccess: uuid => {
      queryCache.invalidateQueries(['assessments', uuid])
    }
  })
