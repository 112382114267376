import React from 'react'

import Spinner from './Spinner'
import { serializeNetworkError } from '../../../helpers'

const GlobalLoading = ({ error }) => {
  if (error) {
    const networkErr = serializeNetworkError(error)
    return (
      <div className="w-full h-full bg-brand-primary">
        <h1 className="text-2xl text-white">{networkErr.header}</h1>
        <p className="text-white">{networkErr.message}</p>
      </div>
    )
  }
  return (
    <div className="w-full h-full bg-brand-primary flex flex-col justify-center items-center">
      <h1 className="text-2xl text-white">Loading My Strengths My Health</h1>
      <div className="text-white mt-8">
        <Spinner />
      </div>
    </div>
  )
}

export default GlobalLoading
