const INITIAL_STATE = {
  applicationName: 'MSMH Dashboard'
}

const mainReducer = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    default:
      return state
  }
}

export default mainReducer
