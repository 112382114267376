import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ReactQueryConfigProvider } from 'react-query'
import * as Sentry from '@sentry/react'
import './styles/tailwind.css'
import * as serviceWorker from './serviceWorker'
import configureStore from './store'
import { ReactQueryConfig } from './api'

import GlobalErrorBoundary from './components/shared/loading/GlobalErrorBoundary'
import App from './components/App'

const ENV = process.env.NODE_ENV || 'development'
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: ENV
})

const store = configureStore()

const renderApp = () =>
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ReactQueryConfigProvider config={ReactQueryConfig}>
          <Sentry.ErrorBoundary fallback={GlobalErrorBoundary}>
            <App />
          </Sentry.ErrorBoundary>
        </ReactQueryConfigProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  )

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./components/App', renderApp)
}

renderApp()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
