import React from 'react'
import cx from 'classnames'

import styles from './Loading.module.css'

const Spinner = ({ style }) => {
  const SpinnerStyle = cx(styles.Ring, styles.Spin)
  return <div className={SpinnerStyle} style={style} />
}

export default Spinner
