import api from './index'
import { useMutation, queryCache, useQuery } from 'react-query'

export const login = async ({ email, password }) =>
  (await api.post('/auth/login', { email, password })).data
export const logout = async () => await api.post('/auth/logout')
export const requestPasswordReset = async email =>
  await api.post('/auth/request-password-reset', { email })
export const passwordReset = async (email, verification_code, new_password) =>
  await api.post('/auth/password-reset', {
    email,
    verification_code,
    new_password
  })
export const fetchVendors = async () => (await api.get('/vendors')).data
export const fetchVendor = async () => (await api.get('/vendor')).data
const createVendor = async ({ name, org_name, email, role }) =>
  (await api.post('/vendor', { name, org_name, email, role })).data
const updateVendor = async ({ id, name, org_name, email, role }) =>
  (await api.put(`/vendor/${id}`, { name, org_name, email, role })).data
const deleteVendor = async id => {
  await api.delete(`/vendor/${id}`)
  return id
}

// Hooks
export const useVendors = () => useQuery('vendors', fetchVendors)
export const useCreateVendor = () =>
  useMutation(createVendor, {
    onMutate: newVendor => {
      const previousVendors = queryCache.getQueryData('vendors')

      queryCache.setQueryData('vendors', old => [...old, newVendor])

      return () => queryCache.setQueryData('vendors', previousVendors)
    },
    onError: (_err, _newVendor, rollback) => rollback(),
    onSettled: () => queryCache.invalidateQueries('vendors')
  })

export const useEditVendor = () =>
  useMutation(updateVendor, {
    onMutate: editedVendor => {
      const vendors = queryCache.getQueryData('vendors')
      const idx = vendors.findIndex(v => v.id === editedVendor.id)
      if (idx === -1) {
        queryCache.setQueryData('vendors', vendors)
      } else {
        const newVendors = [...vendors]
        newVendors[idx] = editedVendor
        queryCache.setQueryData('vendors', newVendors)
      }

      return () => queryCache.setQueryData('vendors', vendors)
    },
    onError: (_err, _editedVendor, rollback) => rollback(),
    onSettled: () => queryCache.invalidateQueries('vendors')
  })
export const useDeleteVendor = () =>
  useMutation(deleteVendor, {
    onMutate: id => {
      const vendors = queryCache.getQueryData('vendors')
      const idx = vendors.findIndex(v => v.id === id)
      if (idx === -1) {
        queryCache.setQueryData('vendors', vendors)
      } else {
        const newVendors = [...vendors]
        newVendors.splice(idx, 1)
        queryCache.setQueryData('vendors', newVendors)
      }

      return () => queryCache.setQueryData('vendors', vendors)
    },
    onError: (_err, _id, rollback) => rollback(),
    onSettled: () => queryCache.invalidateQueries('vendors')
  })
export const useEditUserVendor = () =>
  useMutation(updateVendor, {
    onMutate: vals => {
      const previousVendor = queryCache.getQueryData('vendor')

      queryCache.setQueryData('vendor', old => ({
        ...old,
        ...vals
      }))

      return () => queryCache.setQueryData('vendor', previousVendor)
    },
    onError: (_err, _vals, rollback) => rollback(),
    onSuccess: data => queryCache.setQueryData('vendor', () => data)
  })
