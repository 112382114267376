import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import GlobalLoading from './shared/loading/GlobalLoading'

const Public = React.lazy(() => import('./views/PublicView'))
const Secure = React.lazy(() => import('./Secure'))

const App = () => {
  return (
    <Router>
      <React.Suspense fallback={<GlobalLoading />}>
        <Switch>
          <Route path="/" exact>
            <Public />
          </Route>
          <Route>
            <Secure />
          </Route>
        </Switch>
      </React.Suspense>
    </Router>
  )
}

export default App
