import React from 'react'

import Header from '../../auth/Header'
import { ReactComponent as ErrorIllustration } from '../../../assets/undraw_server_down_s4lk.svg'

const GlobalError = () => (
  <div className="w-full h-full flex flex-col justify-between">
    <Header />
    <div className="flex justify-center items-center flex-grow">
      <ErrorIllustration />
    </div>
  </div>
)

export default GlobalError

// export default class GlobalErrorBoundary extends React.Component {
//   state = {
//     hasError: false
//   }

//   static getDerivedStateFromError(error) {
//     return { hasError: true }
//   }

//   renderError() {
//     return (
//       <div className="w-full h-full flex flex-col justify-between">
//         <Header />
//         <div className="flex justify-center items-center flex-grow">
//           <ErrorIllustration />
//         </div>
//       </div>
//     )
//   }

//   render() {
//     return this.state.hasError ? this.renderError() : this.props.children
//   }
// }
