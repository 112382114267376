import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_VENDOR_SERVICE_URL,
  withCredentials: true,
  responseType: 'json'
})

/**
 * @type {import('react-query').ReactQueryProviderConfig}
 */
export const ReactQueryConfig = {
  queries: {
    retry: false
  }
}

export default api
export * from './vendor'
export * from './assessments'
