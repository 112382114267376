export const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

/**
 * @param {Error} error
 * @returns {{ status: number, header: string, message: string }}
 */
export function serializeNetworkError(error) {
  if (error.response) {
    return {
      status: error.response.status,
      title: error.response.data.title,
      detail: error.response.data.detail
    }
  } else {
    return {
      status: 0,
      header: 'Unknown Error',
      message: 'Something went wrong'
    }
  }
}

/**
 *
 * @param {object} obj Object to perform op
 * @param {string | number} key Key to remove
 * @returns {object} Object without key
 */
export function omit(obj, key) {
  if (!obj || !key) throw new Error('Missing args')
  const { [key]: del, ...rest } = obj
  return rest
}

/**
 *
 * @param {array} arr Array to convert
 * @param {string | number} key Key to use for dictionary
 * @returns {object} Dictionary
 */
export function arrToDict(arr, key) {
  const dict = {}
  arr.forEach(item => (dict[item[key]] = item))
  return dict
}

/**
 *
 * @param {string} str String to capitalize
 * @returns {string} Capitalized string
 */
export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.substring(1)
}

export * from './draggableList'
