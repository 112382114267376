import React from 'react'

import umnLogo from '../../assets/umn_landscape.png'

const Header = () => {
  return (
    <div className="flex justify-between items-center h-full w-full max-w-4xl px-4 py-2 m-auto">
      <div>
        <h1 className="font-bold text-xl text-gray-800">
          My Strengths My Health Dashboard
        </h1>
      </div>
      <img src={umnLogo} alt="UMN Logo" width="200" />
    </div>
  )
}

export default Header
